import {
  VictoryBar,
  VictoryLine,
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryTheme,
  VictoryZoomContainer,
  VictoryBrushContainer,
  VictoryLegend,
} from "victory";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useLoadingStore } from "../../lib/stores/stores";
import axios from "axios";

function CovidPredictor() {
  const [rawData, setRawData] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const get_prediction = async () => {
    setLoading(true);

    let result = await axios
      .get(
        "https://master-dwml-backend-django-lqfbwlkw2a-uc.a.run.app/api/v1/covid_prediction/",
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      
    setRawData(JSON.parse(result.data.graph_data));

    setLoading(false);
  };

  const createGraphData = () => {
    const time_values = rawData.reduce((acc, curr) => [...acc, curr.Date], []);
    const price_values = rawData.reduce(
      (acc, curr) => [...acc, parseFloat(curr["7DA"])],
      []
    );

    const final_result = [];

    time_values.forEach((time, index) => {
      const price = price_values[index];
      var entry = {
        x: time,
        y: price,
      };
      final_result.push(entry);
    });

    let slicedData = final_result.slice(-25, -1);

    setFinalData(slicedData);
  };

  useEffect(() => {
    get_prediction();
  }, []);

  useEffect(() => {
    createGraphData();
  }, [rawData]);

  return (
    <div className="bg-white p-20 h-[100vh]">

        
      {loading === true && (
        <Skeleton sx={{ height: 680 }} animation="wave" variant="rectangular" />
      )}
      {finalData.length > 0  && (
        <div>
          <p className="font-semibold text-2xl pb-4">Covid-19 Prediction</p>
          <p className="text-lg pb-6">( AdaBoost regressor )</p>
          

          <VictoryChart
            scale={{ x: "time", y: "linear" }}
            theme={VictoryTheme.material}
            height={200}
            width={400}
            style={{ parent: { fontSize: 4 } }} // Adjust the fontSize here
          >
            <VictoryLegend
              x={125}
              y={2}
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: "black" } }}
              colorScale={["#DA8C0B", "blue"]}
              data={[{ name: "Actual" }, { name: "Predicted" }]}
            />

            <VictoryAxis dependentAxis />

            <VictoryLine
              style={{
                data: { stroke: "#DA8C0B", strokeLinecap: "round" },
              }}
              animate={{
                onLoad: { duration: 5000 },
              }}
              data={finalData}
            />

            <VictoryLine
              style={{
                data: { stroke: "blue", strokeLinecap: "round" }, // Adjust stroke for differentiation
              }}
              animate={{
                onLoad: { duration: 5000 },
              }}
              data={finalData.slice(-7)} // This selects the last 10 elements from finalData
            />

            <VictoryAxis fixLabelOverlap={true} />
          </VictoryChart>
        </div>
      )}
    </div>
  );
}

export default CovidPredictor;
