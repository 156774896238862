import React from "react";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";

import { Input, Grid, Select, Option, Button } from "@material-tailwind/react";
import { useCountries } from "use-react-countries";
import CircularProgress from "@mui/material/CircularProgress";

function CreditCardInput(props) {
  const { countries } = useCountries();
  const [addNewCard, setaddNewCard] = React.useState(false);

  const existingCards = [
    { name: "My Visa", digits: 123, brand: "visa" },
    { name: "My Mastercard", digits: 456, brand: "mastercard" },
  ];

  return (
    <div className="flex flex-col gap-4 py-12">
      {addNewCard === true && (
        <div className="grid transition-opacity ease-in duration-700 opacity-100 gap-4">
          <Input
            variant="static"
            label="Card Number"
            icon={<i className="fas fa-heart" />}
            placeholder="XXXX XXXX XXXX XXXX"
          />

          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-2">
              <Input variant="static" label="Expiry Date" placeholder="MM/YY" />
            </div>

            <div>
              <Input variant="static" label="CVV" placeholder="xxx" />
            </div>
          </div>

          <Input variant="static" label="Name" placeholder="" />
        </div>
      )}

      {addNewCard === false && (
        <div className="w-full">
          <Select
            size="lg"
            label="Use Existing Card"
            selected={(element) =>
              element &&
              React.cloneElement(element, {
                disabled: true,
                className:
                  "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
              })
            }
          >
            {existingCards.map(({ name, digits, brand }) => (
              <Option
                key={name}
                value={name}
                className="flex items-center gap-2"
              >
                {brand === "visa" && <FaCcVisa />}

                {brand === "mastercard" && <FaCcMastercard />}
                {name}
                <div className="mr-4">
                  ...{digits}

                </div>
              </Option>
            ))}
          </Select>
        </div>
      )}

      {addNewCard === false && (
        <Button
          onClick={() => {
            setaddNewCard(true);
          }}
          variant="outlined"
        >
          Add New Card
        </Button>
      )}

      {addNewCard === true && (
        <Button
          onClick={() => {
            setaddNewCard(false);
          }}
          variant="outlined"
        >
          Use Existing Card
        </Button>
      )}
    </div>
  );
}

export default CreditCardInput;
