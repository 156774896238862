import React from 'react';

export default () => {
    const onDragStart = (event, nodeType, label) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.setData("label", label);
        event.dataTransfer.effectAllowed = "move";
      };

  return (
    <aside>
      <div className="description">You can drag these nodes to the design space</div>
      <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input', "Data Source")} draggable>
        Data Source Node
      </div>
      <div className="dndnode warehouse" onDragStart={(event) => onDragStart(event, 'default', "Data Warehouse")} draggable>
        Data Warehouse Node
      </div>
      <div className="dndnode lake" onDragStart={(event) => onDragStart(event, 'default', "Data Lake")} draggable>
        Data Lake Node
      </div>
      <div className="dndnode analysis" onDragStart={(event) => onDragStart(event, 'default',"Analysis")} draggable>
        Analysis Node
      </div>
      <div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output',"Endpoint")} draggable>
        Endpoint Node
      </div>
    </aside>
  );
};
