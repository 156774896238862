import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  VictoryBar,
  VictoryLine,
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  VictoryTheme,
  VictoryZoomContainer,
  VictoryBrushContainer,
} from "victory";

import { BsCircleFill } from "react-icons/bs";

import empty from "../../Assets/DWML/emptybox.gif";
import rocket from "../../Assets/DWML/rocket.gif";
import moon from "../../Assets/DWML/moon.gif";

function Result(props) {
  const [selectedDomain, setselectedDomain] = React.useState(null);
  const [zoomDomain, setzoomDomain] = React.useState(null);

  const result_image = () => {
    //console.log(props.NumberLambos);

    if (Math.floor(props.NumberLambos) >= 10) {
      return moon;
    } else if (
      Math.floor(props.NumberLambos) < 10 &&
      Math.floor(props.NumberLambos) >= 1
    ) {
      return rocket;
    } else {
      return empty;
    }
  };

  const handleZoom = (domain) => {
    setselectedDomain(domain);
  };

  const handleBrush = (domain) => {
    setzoomDomain(domain);
  };

  const createGraphData = (graphData) => {
    const time_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, new Date(Date.parse(curr.x))],
      []
    );
    const price_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, parseFloat(curr.y)],
      []
    );

    const final_result = [];

    time_values.forEach((time, index) => {
      const price = price_values[index];
      var entry = {
        x: time,
        y: price,
      };
      final_result.push(entry);
    });

    console.log(final_result);
    return final_result;
  };

  const createDateTickData = (graphData) => {
    const time_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, new Date(Date.parse(curr.x))],
      []
    );
    const price_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, parseFloat(curr.y)],
      []
    );

    const final_result = [];

    time_values.forEach((time, index) => {
      const price = price_values[index];
      var entry = {
        x: time,
        y: price,
      };
      final_result.push(entry);
    });

    console.log(final_result);
    return time_values;
  };

  const createScatterData = (graphData) => {
    const time_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, new Date(Date.parse(curr.x))],
      []
    );
    const price_values = JSON.parse(props.GraphData).reduce(
      (acc, curr) => [...acc, parseFloat(curr.y)],
      []
    );

    const final_result = [];

    const max = Math.max(...price_values);

    const maximum_price_index = price_values.indexOf(max);

    var entry = {
      x: time_values[maximum_price_index],
      y: price_values[maximum_price_index],
    };
    final_result.push(entry);

    console.log(final_result);
    return final_result;
  };

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <Col md={12} className="project-card">
        <div className="image-container">
          <img src={result_image()} width={250} height={250} alt="logo" />
        </div>
      </Col>

      <Col md={12} className="project-card">
        <h5>Number of Lambos</h5>
        <h6>{props.NumberLambos}</h6>
      </Col>

      <Col md={6} className="project-card">
        <h5>Initial Coins Bought</h5>
        <h6>{props.initialCoins}</h6>
      </Col>

      <Col md={6} className="project-card">
        <h5>Net profit</h5>
        <h6>$ {props.NetProfit}</h6>
      </Col>

      <Col md={6} className="project-card">
        <h5>Growth Factor</h5>
        <h6>X {props.GrowthFactor}</h6>
      </Col>

      <Col md={6} className="project-card">
        <h5>Initial Investment</h5>
        <h6>$ {props.InitialInvestment}</h6>
      </Col>

      <Col md={12} className="project-card">
        <h5>{props.coinSymbol} Performance</h5>
        <VictoryChart
          padding={{ top: 50, bottom: 50, right: 0, left: 70 }}
          scale={{ x: "time" }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            dependentAxis
            style={{
              grid: { stroke: "white", strokeWidth: 0.5 },
            }}
          />

          <VictoryLine
            style={{
              data: { stroke: "#DA8C0B", strokeLinecap: "round" },
              grid: { stroke: "white", strokeWidth: 1.5 },
              parent: { border: "1px solid #ccc" },
            }}
            animate={{
              onLoad: { duration: 5000 },
            }}
            data={createGraphData(props.GraphData)}
          />

          <VictoryScatter
            size={4}
            style={{ data: { fill: "#236529" } }}
            data={createScatterData(props.GraphData)}
            animate={{
              duration: 5000,
              onLoad: { duration: 5000 }
            }}
          />
          <VictoryAxis fixLabelOverlap={true} />
        </VictoryChart>
      </Col>

      <Col md={12} className="project-card">


        <div
          data-tip
          data-for="global"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <BsCircleFill size={"1rem"} style={{ color: "#236529" }} />
          <span>
            <h6 style={{ paddingLeft: "15px" }}> When you should have sold</h6>
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default Result;
