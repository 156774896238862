import React, { useEffect, useState, useCallback, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { DeepChat } from "deep-chat-react";

import axios from "axios";

function Chat() {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Welcome. I am Hermes, your dedicated AI assistant on the topic of Edwin. How may I be of service to you today?",
      sentTime: "just now",
      role: "Joe",
      direction: "incoming",
    },
  ]);

  const chatElementRef = useRef(null);

  const messageHandler = (body, signals) => {
    try {
      let config = {
        params: {
          request_text: body.messages[0].text,
        },
      };

      axios
        .get(
          "https://master-hermes-backend-lqfbwlkw2a-uc.a.run.app/api/v1/project/core/process_request",
          config
        )
        .then((res) => {
          signals.onResponse({ text: res.data.message }); // Display response message
        })
        .catch((error) => {
          signals.onResponse({ error: error }); // Display error message
        });
    } catch (error) {
      signals.onResponse({ error: error }); // Generic error handling
    }
  };

  return (
    <div className="w-full h-full bg-white overflow-hidden">
      <div className="py-8 items-center justify-center content-center px-8 border-transparent " />

      <div className="md:pt-12, lg:pt-12" />
      <DeepChat
        style={{
          borderWidth: "0",
          border: "0",
          borderColor: "transparent",
          background: "white",
          width: "90vw",
          height: "80vh",
          paddingInline: "40px",
        }}
        textInput={{
          styles: {
            container: {
              borderRadius: "20px",
              border: "unset",
              boxShadow:
                "0 0 10px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1)",
              borderColor: "#047857",
              width: "75%",
              marginLeft: "-40px",
            },
            text: {
              padding: "10px",
              paddingLeft: "15px",
              paddingRight: "34px",
            },
          },
          placeholder: {
            text: "Ask me anything...",
            style: { color: "#606060" },
          },
        }}
        messageStyles={{
          default: {
            shared: {
              bubble: {
                backgroundColor: "unset",
                marginTop: "10px",
                marginBottom: "10px",
              },
            },
            user: {
              bubble: {
                background: "grey",
              },
            },
            ai: {
              bubble: {
                background: "#047857",
                color: "white",
                padding: "10px",
              },
            },
          },
        }}
        microphone={{
          button: {
            default: {
              container: {
                default: {
                  bottom: "1em",
                  right: "0.6em",
                  borderRadius: "20px",
                  width: "1.9em",
                  height: "1.9em",
                },
              },
              svg: {
                styles: { default: { bottom: "0.35em", left: "0.35em" } },
              },
            },
            position: "inside-right",
          },
        }}
        submitButtonStyles={{
          position: "outside-right",
          submit: {
            container: {
              default: {
                bottom: "0.8em",
                borderRadius: "25px",
                padding: "6px 5px 4px",
                backgroundColor: "unset",
                color: "#047857",
              },
            },
            svg: {
              content:
                '<?xml version="1.0" encoding="utf-8"?> <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"/></svg>',
              styles: {
                default: {
                  width: "1.5em",
                  color: "#047857",
                  filter:
                    "brightness(0) saturate(100%) invert(10%) sepia(86%) saturate(6044%) hue-rotate(205deg) brightness(100%) contrast(100%)",
                },
              },
            },
          },
          loading: {
            svg: {
              styles: {
                default: {
                  filter:
                    "brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)",
                },
              },
            },
          },
          stop: {
            container: { hover: { backgroundColor: "#ededed94" } },
            svg: {
              styles: {
                default: {
                  filter:
                    "brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)",
                },
              },
            },
          },
        }}
        introMessage={{
          text: "Welcome. I am Hermes, your dedicated AI assistant on the topic of Edwin. How may I be of service to you today?",
        }}
        ref={chatElementRef}
        request={{
          handler: messageHandler,
        }}
      />

      <div className="pt-12" />
    </div>
  );
}

export default Chat;
