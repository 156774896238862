import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

import dwml from "../../Assets/Projects/DelosBanner.png";
import datascience from "../../Assets/Projects/DSLogo.png";
import analysis from "../../Assets/Projects/DataAnalytics.png";
import Midas from "../../Assets/Projects/MidasCircle.png";
import Orwell from "../../Assets/Projects/ProjectOrwell.png";
import Prometheus from "../../Assets/Projects/Prometheus.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <div className="py-4"/>
      <Container>
        <h1 className="project-heading pb-10">Projects</h1>
        <Row
          style={{
            animation: `fadeIn 2s`,
            justifyContent: "center",
            paddingBottom: "10px",
          }}
        >
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dwml}
              isBlog={false}
              active={true}
              title="Project Delos"
              description="Project Delos is a fully fledged software
              development project from A-Z, showcasing the full
              lifecycle of a software development project,
              duplicated across six seperate web frameworks as
              backends."
              tags={[
                "Flask",    
                "Fastapi",
                "Django",
                "Express",
                "Next.js",
                "Svelekit",
                "ORM",
                "SQL",
                "Devops",
              ]}
              demoLink="/Delos"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={datascience}
              isBlog={false}
              active={true}
              title="Data Science Notebooks"
              description="A collection of my data science related notebooks;
              these notebooks are trying to tackle challenges I see
              in my every day life, or those that are interesting"
              tags={[
                "Keras",
                "Tensorflow",
                "Jupiter",
                "Seaborn",
                "Pandas",
                "EDA",
                "LSTM",
                "RNN",
              ]}
              demoLink="/DS"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={analysis}
              isBlog={false}
              active={true}
              title="Data Engineering Projects"
              description="To round off my own learnings, the data engineering
              projects tackle the nuts and bolts that the leader of
              a tech team should know when handling data at
              scale"
              tags={["Airflow", "BI", "Data Warehousing", "ETL", "PubSub"]}
              demoLink="/DA"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Orwell}
              isBlog={false}
              active={true}
              title="Project Orwell"
              description="Measuring the success of your technology is integral to the product development process. To make good decisions you need good data, this project sets out how to handle and display your in app analytics."
              tags={[
                "Analytics",
                "Streaming",
                "BI",
                "Looker Studio",
                "Big Query",
              ]}
              demoLink="/Orwell"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Midas}
              isBlog={false}
              active={true}
              title="Project Midas"
              description="Now that you've created the next big thing, you need to monetize it. Project Midas gives you the ability to do this in a few different flavours."
              tags={[
                "Invoicing",
                "EFT",
                "Credit Card",
                "QR Payment",
                "Subscription",
                "IAP",
              ]}
              demoLink="/Midas"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Prometheus}
              isBlog={false}
              active={false}
              title="Project Prometheus"
              description="Much like giving fire to mankind was both a blessing and a curse, AI is much the same. This project will focus on creating ML systems from A-Z. Harnessing the fire."
              tags={[
                "MLOPs",    
                "Devops",
                "AI",
                "ML",
              ]}
              demoLink="/Delos"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
