import React from "react";
import { Checkbox } from "@material-tailwind/react";

function Invoice(props) {
  const { numberlineItems, taxRate } = props;

  let lineItems = [];


  for (let step = 0; step < numberlineItems; step++) {
    // Runs 5 times, with values of step 0 through 4.
    lineItems.push({description: `Product ${step}`, amount: 100*(step+1)})
  }

  // Calculate the total of all line items
  const subtotal = props.items.reduce((acc, item) => acc + parseFloat(item.price), 0);

  // Calculate the tax amount
  const tax = subtotal * (taxRate / 100);

  // Calculate the total including tax
  const total = subtotal + tax;

  return (
    <div className="invoice py-4">
      <table className="invoice border-spacing-5 border-separate text-left w-full">
        <thead>
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{parseFloat(item.price).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <td colspan="2"><hr/></td>
          <tr>
            <td>Subtotal</td>
            <td>{subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tax ({taxRate}%)</td>
            <td>{tax.toFixed(2)}</td>
          </tr>
          <td colspan="2"><hr/></td>
          <tr>
            <td>Total</td>
            <td>{total.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>



    </div>
  );
}

export default Invoice;