import React, { useState,useEffect } from "react";
import { Button, Typography } from "@material-tailwind/react";
import { Carousel, IconButton } from "@material-tailwind/react";
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { toast } from "react-toastify";

function FuturisticProductCard(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [heartPressed, setHeartPressed] = useState(false);

  const images = [
    "https://4kwallpapers.com/images/wallpapers/macos-big-sur-apple-layers-fluidic-colorful-wwdc-stock-2048x1536-1455.jpg",
    "https://dynamicwallpaper.club/landing-vids/poster.jpg",
    "https://res.allmacwallpaper.com/get/Retina-MacBook-Air-13-inch-wallpapers/material-style-8k-2560x1600/23774-11.jpg",
    "https://wallpapers.com/images/featured/macbook-pro-n6fd0eensmrcpcsb.jpg",
    "https://512pixels.net/downloads/macos-wallpapers-6k/13-Ventura-Dark.jpg",
    "https://i.pinimg.com/originals/b3/eb/e2/b3ebe2faa2218c42fef54c80a0c9b7f0.jpg",
    "https://e0.pxfuel.com/wallpapers/766/75/desktop-wallpaper-space-colors-blue-purple-stars.jpg",
    "https://wallpapercave.com/wp/wp9139434.jpg",
    "https://wallpapercave.com/wp/wp10203505.jpg",
    "https://c4.wallpaperflare.com/wallpaper/537/926/407/dusk-mojave-california-desert-wallpaper-preview.jpg",
    "https://images.saymedia-content.com/.image/t_share/MTkyNjYzMTEwNjU3Nzc5MTI0/aesthetic-mac-wallpapers.jpg",
    "https://c4.wallpaperflare.com/wallpaper/993/722/421/3d-abstract-3d-wallpaper-preview.jpg",
    "https://i.pinimg.com/736x/9f/7a/00/9f7a0042715479838c35364624063f02.jpg",
    "https://e0.pxfuel.com/wallpapers/315/769/desktop-wallpaper-aesthetic-ideas-aesthetic-mac-space-macbook.jpg",
    "https://swall.teahub.io/photos/small/6-61671_macbook-pro-apple-wallpaper-4k.jpg",
    "https://e0.pxfuel.com/wallpapers/237/334/desktop-wallpaper-abstract-macbook-pro-default.jpg",
    "https://static.vecteezy.com/system/resources/thumbnails/022/515/452/small_2x/ai-generative3d-texture-colorful-abstract-background-for-desktop-wallpaper-image-free-photo.jpg",
    "https://img.freepik.com/photos-premium/vibrant-colorful-shapes-background_969965-10027.jpg",
    "https://e0.pxfuel.com/wallpapers/809/794/desktop-wallpaper-bejeweled-planet-and-mobile-background-1360x768.jpg"
  ]

  const handleFavoriteClick = () => {
    let text = "";
    if (isFavorited) {
      text = "Item removed from favorited";
    } else {
      text = "Item added to favorited";
    }

    console.log(text);

    toast.info(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setIsFavorited(!isFavorited);
  };

  const handleCartClick = () => {
    let text = "";
    if (addedToCart) {
      text = "Item removed from cart";
      props.addition({name:itemName,price:itemPrice},"remove")
    } else {
      text = "Item added to cart";
      props.addition({name:itemName,price:itemPrice},"add")
    }

    toast.info(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setAddedToCart(!addedToCart);

    // You can add additional functionality for handling the cart click here
  };

  const generateUniqueProductName = (baseName) => {
    const randomString = Math.random().toString(36).substring(7);
    return `${baseName} ${randomString}`;
  };

  const [itemName, setItemName] = useState(
    generateUniqueProductName("Product")
  );

  const generateRandomDecimal = () => {
    const randomDecimal = Math.random() * 99 + 1; // Generates a random number between 1 and 100
    return randomDecimal.toFixed(2); // Rounds to two decimal places
  };

  const getRandomImages =(images)=> {
    return images.slice().sort(() => Math.random() - 0.5).slice(0, 4);
  }

  const [randomImages, setrandomImages] = useState(getRandomImages(images));
  const [itemPrice, setItemPrice] = useState(generateRandomDecimal());
  const [image, setImage] = useState(images[Math.floor(Math.random()*images.length)]);
  const [carouselHover, setcarouselHover] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 500);

const handleWindowSizeChange = () => {
  setMobile(window.innerWidth <= 500);
}

useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
    window.removeEventListener('resize', handleWindowSizeChange);
  }
}, []);
  

  return (
    <div className="max-w-xs mx-auto bg-gray-50 text-white rounded-lg overflow-hidden shadow-md transform hover:scale-105 transition-transform duration-300">
      {/* ... (previous code remains the same) */}
      <figure className="relative h-64 w-full">
      <Carousel className="rounded-xl" onMouseEnter={()=>setcarouselHover(true)} onMouseLeave={()=>setcarouselHover(false)} prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handlePrev}
          className={`!absolute top-2/4 left-4 -translate-y-2/4 rounded-full ${carouselHover === true ? "":"sm:invisible"}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
</svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="white"
          size="lg"
          onClick={handleNext}
          className={`!absolute top-2/4 !right-4 -translate-y-2/4 rounded-full ${carouselHover === true ? "":"sm:invisible"} `}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg>
        </IconButton>
      )} navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}>


{randomImages.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Image ${index}`} className="h-full w-full object-cover"/>
      ))}
    </Carousel>
      </figure>

      <div className="p-6">
        <h2 className="text-xl text-gray-800 font-semibold mb-2">{itemName}</h2>
        <p className="text-gray-800 text-sm mb-4">
          Explore the future with our cutting-edge product.
        </p>
        <div className="flex justify-between items-center">
          <span className="text-gray-800 text-base font-bold">
            ${itemPrice}
          </span>

          <div className="flex items-center gap-2 absolute top-5 right-5">

            <button
              className=" dwml-repo-button bg-white flex items-center justify-center w-auto p-1.5  rounded-full "
              onClick={handleFavoriteClick}
              onMouseUp={() => setHeartPressed(false)}
            >
              {isFavorited ? (
                <AiOutlineHeart color="red" className="text-2xl" />
              ) : (
                <AiOutlineHeart className="text-2xl" />
              )}
            </button>
          </div>

          <div className="flex items-center gap-2">
            <button
              className={`dwml-repo-button bg-white flex items-center justify-center w-auto p-3 rounded-full ${
                addedToCart ? "added-to-cart" : ""
              }`}
              onClick={handleCartClick}
            >
              {addedToCart ? (
                <AiOutlineCheckCircle color="#4CAF50" size={"1.1em"} />
              ) : (
                <AiOutlineShoppingCart size={"1.1em"} />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FuturisticProductCard;
