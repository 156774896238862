import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "react-bootstrap/Button";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import InputMask from "react-input-mask";
import { FiArrowRight } from "react-icons/fi";
import { BsFillLockFill, BsBank, BsQrCode } from "react-icons/bs";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

import {
  Button as TButton,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Select,
  Option,
  Badge,
  IconButton,
  Avatar,
} from "@material-tailwind/react";

import { PiGraphThin } from "react-icons/pi";
import { LuCloudCog } from "react-icons/lu";
import { SiLooker } from "react-icons/si";
import {
  PiShoppingCartSimpleBold,
  PiMoneyBold,
  PiCreditCardLight,
} from "react-icons/pi";
import { FaMoneyBillTransfer, FaGooglePay, FaApplePay } from "react-icons/fa6";

import { Checkbox } from "@material-tailwind/react";

import dwml_title from "../../Assets/Midas/MidasLogo.png";
import Invoice from "./invoice";
import Product from "./product";
import CreditCardInput from "./CreditCardInput.jsx";

function Midas() {
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    numLineItems: 4,
    taxRate: 12,
    focus: "",
  });
  const [radioValue, setRadioValue] = useState("Shopping");
  const [itemsInCart, setItemsInCart] = useState(0);
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState("card");
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardExpires, setCardExpires] = React.useState("");
  const [cartItems, setCartItems] = useState([]);

  const payment_options = [
    { option: "Card" },
    { option: "EFT" },
    { option: "QR Code" },
    { option: "Apple Pay" },
    { option: "Google Pay" },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);

    window.analytics.track("Change Payment Method", {
      target: mapping[value],
    });
  };

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const mapping = {
    0: "Card",
    1: "EFT",
    2: "QR Code",
  };

  function formatCardNumber(value) {
    const val = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const matches = val.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }

  function formatExpires(value) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/^([2-9])$/g, "0$1")
      .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
      .replace(/^0{1,}/g, "0")
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
  }

  const handleCartAdd = (cartItem, operation) => {
    if (operation === "add") {
      setItemsInCart(itemsInCart + 1);
      setCartItems([...cartItems, cartItem]);
    } else {
      if (itemsInCart - 1 >= 0) {
        setItemsInCart(itemsInCart - 1);
        const updatedItems = cartItems.filter(
          (item) => item.name !== cartItem.name
        );
        setCartItems(updatedItems);
      }
    }

    console.log(cartItems);
  };

  return (
    <Container fluid className="project-section">
      <Container style={{ animation: `fadeIn 2s` }}>
        <div className="image-container py-8">
          <img src={dwml_title} width={"300px"} height={"100px"} alt="logo" />
        </div>

        <div class="float-right">
          <Badge
            content={String(itemsInCart)}
            class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
          >
            <IconButton
              onClick={() => {
                toast.info(
                  "This will take you to the checkout/invoicing screen"
                );
              }}
            >
              <PiShoppingCartSimpleBold className="h-6 w-6" color="black" />
            </IconButton>
          </Badge>
        </div>

        <Tabs value={radioValue} className="overflow-hidden py-8 w-full">
          <TabsHeader className="relative z-0 ">
            <Tab value="Shopping" onClick={() => setRadioValue("Shopping")}>
              <div className="flex">
                <PiShoppingCartSimpleBold className="pr-2" size={28} /> Shopping
              </div>
            </Tab>
            <Tab value="Paying" onClick={() => setRadioValue("Paying")}>
              <div className="flex">
                <PiMoneyBold className="pr-2" size={28} /> Paying
              </div>
            </Tab>
          </TabsHeader>

          <TabsBody className="!overflow-x-hidden !overflow-y-visible">
            <TabPanel value="Shopping" className="p-0">
              <div class="flex flex-wrap justify-center gap-12 py-8">
                <Product addition={handleCartAdd} />

                <Product addition={handleCartAdd} />

                <Product addition={handleCartAdd} />

                <Product addition={handleCartAdd} />

                <Product addition={handleCartAdd} />

                <Product addition={handleCartAdd} />
              </div>
            </TabPanel>

            <TabPanel value="Paying" className="p-0">
              <Card className="w-full ">
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="m-0 grid place-items-center rounded-b-none pt-8 pb-2 px-4 text-center"
                >
                  <Typography variant="h4" color="black">
                    Ashes Checkout
                  </Typography>
                </CardHeader>
                <CardBody>
                  <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div className="grid gap-2">

                      <Input
                        type="number"
                        name="numLineItems"
                        value={itemsInCart}
                        onFocus={handleInputFocus}
                        disabled
                        placeholder="Number of Line Items"
                        startAdornment={
                          <InputAdornment position="start">
                            Number of Line Items
                          </InputAdornment>
                        }
                      />

                      <Input
                        type="number"
                        name="taxRate"
                        value={state.taxRate}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder="Tax Rate"
                        startAdornment={
                          <InputAdornment position="start">
                            Tax Rate %
                          </InputAdornment>
                        }
                      />

                      <Invoice
                        numberlineItems={itemsInCart}
                        taxRate={state.taxRate}
                        items={cartItems}
                      />
                    </div>

                    <div>
                      <Tabs value={type}>
                        <TabsHeader className="overflow-x-auto no-scrollbar flex-no-wrap ">
                          <Tab value="card" onClick={() => setType("card")}>
                            <PiCreditCardLight className="pr-2" size={42} />
                          </Tab>
                          <Tab
                            value="google pay"
                            onClick={() => setType("google pay")}
                          >
                            <FaGooglePay className="pr-2" size={42} />
                          </Tab>

                          <Tab
                            value="apple pay"
                            onClick={() => setType("apple pay")}
                          >
                            <FaApplePay className="pr-2" size={36} />
                          </Tab>
                        </TabsHeader>
                        <TabsBody className="!overflow-x-hidden !overflow-y-hidden">
                          <TabPanel value="card" className="p-0">
                          <CreditCardInput/>
                          </TabPanel>

                          <TabPanel value="google pay" className="p-0">
                            <div className=" py-8 rounded-lg text-black text-center">
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Google_Pay_Logo.svg/2560px-Google_Pay_Logo.svg.png"
                                alt="Google Pay Logo"
                                className="mx-auto mb-4 h-12"
                              />
                              <h3 className="text-2xl font-bold mb-2">
                                Use Google Pay
                              </h3>
                              <p className="text-sm mb-4">
                                Fast, secure payments with Google Pay.
                              </p>
                            </div>
                          </TabPanel>

                          <TabPanel value="apple pay" className="p-0">
                            <div className=" py-8 rounded-lg text-black text-center">
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Apple_Pay_logo.svg/2560px-Apple_Pay_logo.svg.png"
                                alt="apple Pay Logo"
                                className="mx-auto mb-4 h-12"
                              />
                              <h3 className="text-2xl font-bold mb-2">
                                Use Apple Pay
                              </h3>
                              <p className="text-sm mb-4">
                                Fast, secure payments with Apple Pay.
                              </p>
                            </div>
                          </TabPanel>
                        </TabsBody>
                      </Tabs>
                    </div>
                  </div>
                  <div className="py-4">
                    <Button
                      className="dwml-repo-button w-full"
                      onClick={() => {
                        toast("Let's pretend the payment has processed", {
                          position: "top-center",
                          autoClose: 5000,
                          theme: "light",
                        });
                        window.analytics.track("Payment Process", {
                          target: mapping[value],
                          line_items: state.numLineItems,
                          tax_rate: state.taxRate,
                        });
                      }}
                      variant="primary"
                    >
                      <div className="flex text-center justify-center align-middle items-center rounded-lg">
                        <p>Process Payment</p>
                        <FiArrowRight />
                      </div>
                    </Button>

                    <Typography
                      variant="small"
                      color="gray"
                      className="mt-2 flex items-center justify-center gap-2 font-normal opacity-60"
                    >
                      <BsFillLockFill size={12} />
                      Payments are secure and encrypted
                    </Typography>
                  </div>
                </CardBody>
              </Card>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </Container>
    </Container>
  );
}

export default Midas;
