import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Accordion from "react-bootstrap/Accordion";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import myImg from "../../Assets/avatar.svg";
import { JupyterNotebookViewer } from "react-jupyter-notebook-viewer";
import BitBot from "../../Assets/NoteBooks/BitBot.ipynb";
import DoctorRoster from "../../Assets/NoteBooks/DoctorRoster.ipynb";
import CovidPredictor from "../../Assets/NoteBooks/CovidPredictor.ipynb";

import {
  Button as TButton,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Select,
  Option,
} from "@material-tailwind/react";



function DS() {
  const [radioValue, setRadioValue] = useState("BitBot");
  const [isLoading, setLoading] = useState(false);

  const radios = [
    { name: "BitBot", value: "BitBot" },
    { name: "RoctorDostor", value: "RoctorDostor" },
  ];

  const returnFocusNotebook = () => {
    if (radioValue === "BitBot") {
      return BitBot;
    }
    else if(radioValue === "CovidPredictor"){
      return CovidPredictor
    }
    else {
      return DoctorRoster;
    }
  };

  return (
    <Container fluid className="project-section">
      <div className="py-4"/>
      <Container style={{animation: `fadeIn 2s`}}>
        <h1 className="project-heading py-4">Data Science Notebooks</h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={12} >
            <h4 className="py-4">Which notebook would you like to see?</h4>

            <Tabs value={radioValue} className="overflow-visible">
          <TabsHeader className="relative z-0 ">
            <Tab value="BitBot" onClick={() => setRadioValue("BitBot")}>
            BitBot
            </Tab>
            <Tab value="RoctorDostor" onClick={() => setRadioValue("RoctorDostor")}>
            RoctorDostor
            </Tab>
            <Tab value="CovidPredictor" onClick={() => setRadioValue("CovidPredictor")}>
            CovidPredictor
            </Tab>
          </TabsHeader>
          <TabsBody
            className="!overflow-x-hidden !overflow-y-visible text-left"
          >
            <JupyterNotebookViewer
            filePath={returnFocusNotebook()}
            notebookInputLanguage="html"
            // notebookOutputLanguage="python"
            inputCodeDarkTheme={true}
            outputDarkTheme={true}
            inputMarkdownDarkTheme={true}
            showInputLineNumbers={true}
            showOutputLineNumbers={false}
            // outputTextClassName="output-text"
            // inputTextClassName="input-text"
            // outputBlockClassName="output-block"
            // outputImageClassName="output-image"
            // outputOuterClassName="output-outer"
            // inputOuterClassName="input-outer"
            // outputBorderClassName="output-border"
            // inputBorderClassName="input-border"
            // outputTableClassName="output-table"
            // inputMarkdownBlockClassName="input-markdown-block"
            // inputCodeBlockClassName="input-code-block"
            withOnClick={true}
            hideCodeBlocks={false}
            hideMarkdownBlocks={false}
            hideAllOutputs={false}
            hideAllInputs={false}
          />


            
          </TabsBody>
        </Tabs>
        

          </Col>

        </Row>
      
      </Container>
    </Container>
  );
}

export default DS;
