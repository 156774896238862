import React,{ useState, useEffect } from "react";
import TextTransition, { presets } from 'react-text-transition';


function Type(props) {
  
  const [titles, settitles] = useState([
    "Co-founder",
          "CTO",
          "World Economic Forum Top Innovator",
          "Lead Software Engineer",
          "Cloud Architect",
          "Dungeon Master",
          "Senior Fullstack Engineer",
          "Management Engineer"
  ]);

  const [colors, setColors] = useState([
    "#758b99", // Grayish Blue
  "#5e7f64", // Sage Green
  "#6d8891", // Steel Blue
  "#46545e", // Dark Slate Gray
  "#596f7e", // Cadet Blue
  "#677e8e", // Slate Gray
  "#536872", // Dark Cyan
  "#4a5d67", // Gunmetal
  "#47525e", // Dark Steel Blue
  "#394240", // Eerie Black
  ]);

  const [index, setIndex] = useState(0);
  const [delay, setDelay] = useState(props.delay)


  const onAnimationIteration = () => {
    setIndex((prevIndex) => {
      // reset index if current index is greater than array size
      return prevIndex + 1 < titles.length ? prevIndex + 1 : 0;
    });
  };

  return (

    <div className="flex items-center justify-center">
      <div
        className="titles-container rounded-full p-2 transition-all duration-500"
        style={{
          backgroundColor: colors[index % colors.length],
          animation: `fadeInOut 5s infinite`,
          fontFamily: "'Google Sans', sans-serif", // Apply Google Sans font
        }}
      >
        <p
          className=" desktop:text-4xl tablet:text-4xl phone: text-white font-bold px-8" // Set text color to white and use a bold font weight
          onAnimationIteration={onAnimationIteration}
          style={{ animation: `fadeInOut 5s infinite` }}
        >
          {titles[index]}
        </p>
      </div>
    </div>

  );
}

export default Type;
