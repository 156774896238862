import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Monogram from "../Assets/Monogram.png";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { MdOutlineDesignServices } from "react-icons/md";
import { GiWingfoot } from "react-icons/gi";
import { useLoadingStore } from "../lib/stores/stores";

function NavBar() {

  const navMapping={
    '/Projects':0,
    '/':1,
    '/Chat':2,
    '/Delos':0,
    '/Midas':0,
    '/Orwell':0,
    '/DS':0,
    '/DA':0
  }
  
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [value, setValue] = React.useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const Location = useLocation();  


  

  const navigate = useNavigate();
  const isKeyboardOpen = useDetectKeyboardOpen();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  function handleChange(newValue){
    setValue(newValue);
    console.log(newValue)

    const mapping = ["/Projects","/","/Chat"]

    navigate(mapping[newValue]);


  }

  window.addEventListener("scroll", scrollHandler);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const [isDarkMode, setDarkMode] = React.useState(false);

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
  };

  useEffect(() => {
    const target = '/'+Location.pathname.split('/')[1]
    console.log(target)
    console.log(navMapping[target])
    setValue(navMapping[target])

    window.analytics.track("Navigation", {
      target: Location.pathname.split('/')[1],

    });
  }, [Location]);


  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isLoading = useLoadingStore((state) => state.loading)

  return (
    <div>
      {width > 768 && (
        <Navbar
          expanded={expand}
          fixed="top"
          expand="md"
          className={navColour ? "sticky" : "navbar"}
        >
          <Container>
            <Navbar.Brand href="/" className="d-flex">
              <img
                src={Monogram}
                alt="EK Logo"
                style={{ height: "2rem", width: "2rem" }}
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => {
                updateExpanded(expand ? false : "expanded");
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </Navbar.Toggle>
              <Nav className="ms-auto" defaultActiveKey="#home">
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/"
                    onClick={() => {updateExpanded(false);setValue(1)}}
                    className="text-black z-40"
                  >
                    Home
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/Projects"
                    onClick={() =>  {updateExpanded(false);setValue(0)}}
                  >
                    Projects
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/Chat"
                    onClick={() =>  {updateExpanded(false);setValue(2)}}
                  >
                    Hermes
                  </Nav.Link>
                </Nav.Item>
              </Nav>
          </Container>
          {isLoading == true &&
            <LinearProgress
              sx={{
                position: 'absolute',
                bottom: 0,
                left:0,
                width: '100%',
                "& .MuiLinearProgress-bar": {
                  backgroundImage: 'linear-gradient(to right, white, black, white)',
                  borderBottomColor: 'transparent'
                },
                backgroundImage: 'linear-gradient(to right, white, white)', // Gradient from green to yellow
              }}
            />
}
        </Navbar>
      )}
      {width <= 768 && !isKeyboardOpen && (
        <div className="fixed inset-x-0 bottom-0 z-10">
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            className="sticky_bottom , border-[#d3d3d3]"
            //className="bg-[#c1c1c7a9] blur-lg"
          >
            <BottomNavigationAction
              label="Projects"
              icon={<MdOutlineDesignServices />}
              style={{ color: value === 0 ? 'black' : '#d3d3d3' }}
            />
            <BottomNavigationAction
              label="Home"
              icon={
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 158.000000 155.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,155.000000) scale(0.100000,-0.100000)"
                    fill={value === 1 ? 'black' : '#d3d3d3'}
                    stroke="none"
                  >
                    <path
                      d="M450 1270 l0 -280 -225 0 -225 0 0 -90 0 -90 225 0 225 0 0 -405 0
-405 565 0 565 0 0 85 0 85 -460 0 -460 0 0 320 0 320 253 2 252 3 3 88 3 87
-256 0 -255 0 0 195 0 195 445 0 445 0 0 85 0 85 -550 0 -550 0 0 -280z"
                    />
                  </g>
                </svg>
              }
              style={{ color: value === 1 ? 'black' : '#d3d3d3' }}
            />
            <BottomNavigationAction label="Hermes" style={{ color: value === 2 ? 'black' : '#d3d3d3' }} icon={<GiWingfoot />} />
          </BottomNavigation>
          {isLoading == true &&
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                "& .MuiLinearProgress-bar": {
                  backgroundImage: 'linear-gradient(to right, white, black, white)'
                },
                backgroundImage: 'linear-gradient(to right, white, white)', // Gradient from green to yellow
              }}
            />
          </Box>
}
        </div>
      )}
    </div>
  );
}

export default NavBar;
