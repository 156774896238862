import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker.js";
import GA4React from "ga-4-react";

import analytics from "react-segment"

import { ThemeProvider } from "@material-tailwind/react";





const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

//Setup GA tracking
try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-7XSQGBSC2J");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
      console.error(err);
}

//Setup segment tracking
try{
  analytics.default.load("1CKshT12MQb6BbedaAzENT7ckgOBOtQi");

}
catch(err){
  console.error(err);

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.unregister('/service-worker.js');
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
