import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
} from "react-flow-renderer";
import { Img } from "react-image";
import Skeleton from '@mui/material/Skeleton';

import { PiGraphThin } from "react-icons/pi";
import { LuCloudCog } from "react-icons/lu";
import { SiLooker } from "react-icons/si";

import {
  Button as TButton,
  Card,
  CardHeader,
  CardBody,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Select,
  Option,
} from "@material-tailwind/react";

import axios from "axios";

import Iframe from "react-iframe";

import ETL_Pipeline_Diagram from "../../Assets/DA/ETL_Pipeline_Diagram.png";

import Sidebar from "./Sidebar";
import "./dag-css.css";

import { DataGrid } from "@mui/x-data-grid";

export default function DS() {
  const [radioValue, setRadioValue] = useState("DAG Builder");
  const [isLoading, setLoading] = useState(false);
  const [DashLoading, setDashLoading] = useState(true);

  const radios = [
    { name: "DAG Builder", value: "DAG Builder" },
    { name: "ETL", value: "ETL" },
    { name: "Live Dashboard", value: "Live Dashboard" },
  ];

  const initialNodes = [
    {
      id: "1",
      type: "input",
      data: { label: "Data Source" },
      position: { x: 250, y: 5 },
    },
  ];

  let id = 0;
  const getId = () => `dndnode_${id++}`;

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [TopTopics, setTopTopics] = useState([
    { Name: "Elon", id: 1 },
    { Name: "SARB", id: 2 },
    { Name: "Annie", id: 3 },
    { Name: "Bokke", id: 4 },
    { Name: "Bongi", id: 5 },
    { Name: "Bravo", id: 6 },
    { Name: "Corny", id: 7 },
    { Name: "Italy", id: 8 },
    { Name: "Kolbe", id: 9 },
    { Name: "Qatar", id: 10 },
    { Name: "Trump", id: 11 },
    { Name: "Ayanda", id: 12 },
    { Name: "Dj Hlo", id: 13 },
    { Name: "Martha", id: 14 },
    { Name: "Ntsiki", id: 15 },
    { Name: "Willie", id: 16 },
    { Name: "Stage 4", id: 17 },
    { Name: "#ITAvRSA", id: 18 },
    { Name: "#ssrugby", id: 19 },
    { Name: "Charlize", id: 20 },
    { Name: "Italians", id: 21 },
    { Name: "Mastodon", id: 22 },
    { Name: "ROYAL AM", id: 23 },
    { Name: "Willemse", id: 24 },
    { Name: "Afrikaans", id: 25 },
    { Name: "Budweiser", id: 26 },
    { Name: "Master KG", id: 27 },
    { Name: "Rick Ross", id: 28 },
    { Name: "sabc plus", id: 29 },
    { Name: "shop l10b", id: 30 },
    { Name: "#RHODurban", id: 31 },
    { Name: "SHEGGZ OLU", id: 32 },
    { Name: "Standerton", id: 33 },
    { Name: "#Springboks", id: 34 },
    { Name: "Manie Libbok", id: 35 },
    { Name: "Ras Dumisani", id: 36 },
    { Name: "#WooliesBraai", id: 37 },
    { Name: "Julius Malema", id: 38 },
    { Name: "The Peripheral", id: 39 },
    { Name: "Viva Argentina", id: 40 },
    { Name: "National Anthem", id: 41 },
    { Name: "Gianni Infantino", id: 42 },
    { Name: "Hopefully Neymar", id: 43 },
    { Name: "Kurt-Lee Arendse", id: 44 },
    { Name: "YOU KNOW WHAT YEAH", id: 45 },
    { Name: "BELLA X BEAUTIFULBODY", id: 46 },
    { Name: "army team south africa", id: 47 },
    { Name: "eastgate shopping centre", id: 48 },
    { Name: "absa business evolve account", id: 49 },
    { Name: "CONGRATULATIONS BEAUTY TUKURA", id: 50 },
    { Name: "Blom", id: 51 },
    { Name: "Guns", id: 52 },
    { Name: "Nota", id: 53 },
    { Name: "Oupa", id: 54 },
    { Name: "Eskom", id: 55 },
    { Name: "Messi", id: 56 },
    { Name: "Shape", id: 57 },
    { Name: "Sjava", id: 58 },
    { Name: "Thami", id: 59 },
    { Name: "Top G", id: 60 },
    { Name: "Giroud", id: 61 },
    { Name: "Libbok", id: 62 },
    { Name: "London", id: 63 },
    { Name: "Neymar", id: 64 },
    { Name: "Ayepyep", id: 65 },
    { Name: "Benzema", id: 66 },
    { Name: "Cassper", id: 67 },
    { Name: "Ecuador", id: 68 },
    { Name: "England", id: 69 },
    { Name: "Mapimpi", id: 70 },
    { Name: "Martial", id: 71 },
    { Name: "Pitbull", id: 72 },
    { Name: "Route 2", id: 73 },
    { Name: "Stage 5", id: 74 },
    { Name: "Uruguay", id: 75 },
    { Name: "Number 2", id: 76 },
    { Name: "Woodmead", id: 77 },
    { Name: "6 Nations", id: 78 },
    { Name: "Argentina", id: 79 },
    { Name: "Lady Zamar", id: 80 },
    { Name: "#CastleLite", id: 81 },
    { Name: "Pearl Thusi", id: 82 },
    { Name: "#KeLiteStyle", id: 83 },
    { Name: "Ashi Nthwela", id: 84 },
    { Name: "#FIFAWorldCup", id: 85 },
    { Name: "#RIPDJSumbody", id: 86 },
    { Name: "Louis Vuitton", id: 87 },
    { Name: "Arabile Gumede", id: 88 },
    { Name: "Blessed Sunday", id: 89 },
    { Name: "Monate Mpolaye", id: 90 },
    { Name: "Alles Les Bleus", id: 91 },
    { Name: "you know what yeah", id: 92 },
    { Name: "CONGRATULATIONS BELLA", id: 93 },
    { Name: "#JusticeForBafanaSithole", id: 94 },
  ]);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const label = event.dataTransfer.getData("label");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: label },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const get_top_topics = async () => {
    setLoading(true);

    axios
      .get(
        "https://master-twitter-warehouse-api-lqfbwlkw2a-uc.a.run.app/api/v1/project/core/process_request",
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log("Tile api data");
        console.log(res.data.data);

        setTopTopics(res.data.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    get_top_topics();
  }, []);

  return (
    <Container fluid className="project-section">
      <div className="py-4"/>
      <Container style={{ animation: `fadeIn 2s` }}>
        <h1 className="project-heading">Data Engineering</h1>

        <h4 className="py-4">Which project would you like to see?</h4>


        <Tabs value={radioValue} className="overflow-hidden py-8">
          <TabsHeader className="relative z-0 ">
            <Tab value="DAG Builder" onClick={() => setRadioValue("DAG Builder")}>
              <div className="flex">
            <PiGraphThin className="pr-2" size={28} /> DAG Builder
            </div>
            </Tab>
            <Tab value="ETL" onClick={() => setRadioValue("ETL")}>

            <div className="flex">
            <LuCloudCog className="pr-2" size={28} /> ETL Pipeline
            </div>
            
            </Tab>
            <Tab value="Live Dashboard" onClick={() => setRadioValue("Live Dashboard")}>


            
            <div className="flex">
            <SiLooker className="pr-2" size={28} /> Live Dashboard
            </div>
            
            </Tab>
          </TabsHeader>
          <TabsBody
            className="!overflow-x-hidden !overflow-y-visible"
          >

<TabPanel value="DAG Builder" className="p-0">
<Row
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
            }}
            className="py-4"
          >
            <Col  md={12}>
              <h4>
                A good start in Data Engineering is how you design data
                pipelines, this tool below can be used to visualize a data
                pipeline, helping you to translate that flow into an
                orchestration tool like Apache Airflow or any of its derivatives
              </h4>
            </Col>
            <div className="dndflow">
              <ReactFlowProvider>
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    fitView
                  >
                    <Controls />
                  </ReactFlow>
                </div>
                <Sidebar />
              </ReactFlowProvider>
            </div>
          </Row>

  </TabPanel>


  <TabPanel value="ETL" className="p-0">
  <Row
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
            }}
            className="py-4"
          >
            <Col className="py-8" md={12}>
              <h5>
                ETL pipelines handle incoming data in a scalable way, allowing
                data to reach an endpoint reliably. Below is an example using
                Twitters API as the data source, creating a pipeline that tracks
                the top twitter topics within and around the five major
                population centers in South Africa, with data streamed in daily.
              </h5>
              <h5 style={{ paddingTop: "20px" }}>
                This is an example of a batch data pipeline, we could turn this
                into a streaming pipeline by adding a pub/sub step to write into
                the raw table rather than reading straight from the cloud
                storage bucket
              </h5>
              <h5 style={{ paddingTop: "20px" }}>
                Please note this tool may stop working in the future due to Twitter restricting free api access
              </h5>
            </Col>
            <img
              src={ETL_Pipeline_Diagram}
              width={280}
              height={90}
              alt="logo"
            />
            {isLoading === true && (
              <Col style={{ padding: "40px" }} md={12}>
                <h5 style={{ padding: "20px" }}>Top Topics Today</h5>
                  <Skeleton sx={{ height: 400 }} animation="wave" variant="rectangular" />
                
              </Col>
            )}

            {isLoading === false && (
              <Col style={{ padding: "40px" }} md={12}>
                <h5 style={{ padding: "20px" }}>Top Topics Today</h5>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid
                    rows={TopTopics}
                    columns={[
                      { field: "id", headerName: "Rank" },
                      { field: "Name", headerName: "Topic", width: 700 },
                    ]}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </Col>
            )}
          </Row>

  </TabPanel>


  <TabPanel value="Live Dashboard" className="p-0">
  <Row
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
            className="py-4"
          >
            <Col className="py-8" md={12}>
              <h4>
                The last step in Data Engineering is showcasing your data in a
                useful way, here I have used Google's Looker Studio platform to
                showcase data that is being live streamed from my cloud data warehouse
              </h4>
            </Col>

            {DashLoading === true &&
            <Skeleton sx={{ height: 680 }} animation="wave" variant="rectangular" />
}

            <Iframe
              url="https://datastudio.google.com/embed/reporting/6f0df7a2-566d-4d7c-9fc5-c88f8b20ccd0/page/p_4k8r7xri0c"
              width="100%"
              id="myId"
              height="680px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              onLoad={()=>setDashLoading(false)}
            />
          </Row>

  </TabPanel>





          </TabsBody>
        </Tabs>



      </Container>
    </Container>
  );
}
