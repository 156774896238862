import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Type2 from "./Type2";


function Home2() {
  return (

        <Type2/>

  );
}
export default Home2;
