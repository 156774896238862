import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import file from "../../Assets/PrivacyPolicy.md";

function PrivacyPolicy() {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (

    <div className="flex py-40 bg-white justify-center ">
   
      <ReactMarkdown className="prose " children={markdown} />

      </div>
  );
}

export default PrivacyPolicy;
