import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsCircleFill } from "react-icons/bs";
import { BrowserRouter as Router, Link } from "react-router-dom";
import React, { useEffect, useState, useRef, Fragment } from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

function ProjectCards(props) {
  const [hover, setHover] = useState(false);

  return (
    <Link to={props.active ? props.demoLink : '#'}>
     
    <Card className="project-card-view" onClick={() => {
        if (props.active) {
          window.analytics.track("Project Opened", { project: props.title });
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <div className={`text-xl ${hover ? 'bg-gradient-to-r from-black via-[#047857] via-[#065f46] via-[#047857] to-black bg-clip-text text-transparent' : ''}`}>{props.title}</div>

        <hr class="my-6 h-px border-t-0 bg-gradient-to-r from-transparent via-gray-700 to-transparent opacity-50" />
        
        <div className="text-justify text-center tracking-tight font-light py-2 px-4">
          {props.description}
        </div>
        <div className="flex-row flex-wrap py-2 space-x-2 space-y-1 px-2 pb-2">
          {props.tags.map(function (d, idx) {
            return (
              <Chip
                size="small"
                className="bg-slate-400 text-black opacity-50"
                label={d}
              />
            );
          })}
        </div>
      </Card.Body>
      <div className="w-full">
        {props.active === false && (
          <div className="rounded-bl-xl w-full h-10 overflow-hidden bg-[#FCC808]">
            <p className="pt-2">Under construction</p>
          </div>
        )}
      </div>
    </Card>
    </Link>
  );
}
export default ProjectCards;
